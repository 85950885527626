import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import bellimage from "@/images/z-9/bell.png"

const ProgramPopup = () => {
  const [active, setActive] = useState(false)
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset)
    window.removeEventListener("scroll", onScroll)
    window.addEventListener("scroll", onScroll, { passive: true })

    if (window.location.pathname !== '/pl/' && window.location.pathname !== '/en/') {
      setTimeout(() => setActive(true), 1000)
    }

    return () => window.removeEventListener("scroll", onScroll)
  }, [setActive])
  return (
    <ProgramPopupContainer className={`${active ? "active" : null}`}>
      <div>
        <button className={`closeit`} onClick={() => setActive(false)}>
          &times;
        </button>
        <Link to={"/program-3000"}>
          <img src={bellimage} alt={`bell`} />
          <p>
            <strong>POLEĆ WILLBUD</strong>
            <br />
            <span>
              odbierz <strong>3000zł</strong>
            </span>
          </p>
        </Link>
      </div>
    </ProgramPopupContainer>
  )
}

export default ProgramPopup

export const ProgramPopupContainer = styled.div`
  position: fixed;
  top: 130px;
  right: 0;

  z-index: 100000;
  transform: translateX(105%);
  transition: 0.65s cubic-bezier(0.33, 0.99, 0.46, 1.19);

  @media (max-width: 1200px) {
    top: 280px;
    display: none;
  }

  > div {
    padding: 15px 125px 15px 15px;
    background: #94c835;
    color: white;
    position: relative;
    font-weight: 300;
    font-size: 1.1em;
    line-height: 1.1;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 0 0 13px 3px rgba(0, 0, 0, 0.2);

    a {
      display: flex;
      align-items: center;
      gap: 15px;
      color: white;
    }
    p {
      margin: 0;
      white-space: nowrap;
    }
    strong {
      font-weight: 500;
      letter-spacing: 1px;
    }

    span {
      letter-spacing: 2px;
      white-space: nowrap;
    }
  }

  .closeit {
    position: absolute;
    left: -10px;
    top: -10px;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 1px solid #94c835;
    border-radius: 50%;
    color: #94c835;
    font-weight: 300;
    font-size: 14px;
  }

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #94c835;
    content: "";
    border-radius: 5px;
    opacity: 0.6;
    transform-origin: 100px center;
    transform: scale(1);
    transition: 0s;
    z-index: -2;

    visibility: visible;
  }

  &.active {
    transform: translateX(100px);

    &:after {
      // transition: 1s 0.4s;
      animation: 1s 3 0.4s pulse;
    }
  }

  @keyframes pulse {
    0% {
      opacity: 1;
      transform: scale(1);
      visibility: visible;
    }
    100% {
      opacity: 0;
      transform: scale(2);
      visibility: hidden;
    }
  }
`
