import LogoImg from "../../../images/logosvg.svg"
import React from "react"
import styled from "styled-components"

const Logo = ({ theme }) => (
  <LogoStyled theme={theme}>
    <img src={LogoImg} alt="" />
    <p>
      will<span>bud</span>
    </p>
  </LogoStyled>
)
const LogoStyled = styled.div`
  font-family: "Exo 2";
  display: flex;
  flex-direction: row;
  align-items: center;
  transform: scale(0.8);
  transform-origin: 0 50%;
  p {
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    padding-left: 15px;
    font-weight: bold;
    font-size: 26px;
    //height: 42px;
    color: ${props => (props.theme === "dark" ? "#000" : "#fff")};
    span {
      font-weight: normal;
    }
  }
  img {
    height: 42px;
  }
`
export default Logo
