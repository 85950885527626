import React from "react"
import pl from "../../images/pl.png"
import ru from "../../images/ru.png"
import en from "../../images/uk.png"
import de from "../../images/de.png"
import styled from "styled-components"
import { DropdownStyled } from "./navbarStyle"
import OutsideClickHandler from "react-outside-click-handler"
import LanguageContext from "../../context/LanguageContext"
import { IntlContextConsumer, changeLocale, injectIntl } from "gatsby-plugin-intl"

export const LanguageDropdownButton = ({ theme }) => {
  return (
    <>
      <LanguageContext.Consumer>
        {language => (
          <LanguageDropdownStyled>
            <div className="language" onClick={language.handleOpenDropdown}>
              <div className="flag">
                {/* <img*/}
                {/*  src={*/}
                {/*    language.currentLanguage === "pl"*/}
                {/*      ? pl*/}
                {/*      : language.currentLanguage === "de"*/}
                {/*      ? de*/}
                {/*      : language.currentLanguage === "ru"*/}
                {/*      ? ru*/}
                {/*      : language.currentLanguage === "en" ? en : 'pl'*/}
                {/*  }*/}
                {/*  alt=""*/}
                {/*/> */}
                <img src={language.currentLanguage === "pl" ? pl : en} alt=""/>
                <p
                  style={{
                    color:
                      theme === "light" ? "#fff" : theme === "dark" && "#000",
                  }}
                >
                  {language.currentLanguage === "ru"
                    ? "РУ"
                    : language.currentLanguage}
                </p>
              </div>
            </div>
          </LanguageDropdownStyled>
        )}
      </LanguageContext.Consumer>
    </>
  )
}
export const LanguageDropdown = () => {
  return (
    <LanguageContext.Consumer>
      {languageContext => (
        <OutsideClickHandler
          onOutsideClick={() => {
            languageContext.showDropdown && languageContext.handleCloseDropdown()
          }}
        >
          <DropdownLanguageStyled>
            <DropdownStyled
              languageDropdown={true}
              pose={languageContext.showDropdown ? "show" : "hide"}
            >
              <IntlContextConsumer>
                {({ languages, language }) => (
                  <div className="list-lang">
                    {language !== languageContext.currentLanguage && languageContext.setCurrentLanguage(language)}
                    {languages.map((lang, i)=> {
                      return (
                      <div key={i}>
                      <SingleLang language={languageContext} selectLanguage={lang} />
                      {console.log(lang)
                      }
                      </div>)
})}
                  </div>
                )}
              </IntlContextConsumer>
            </DropdownStyled>
          </DropdownLanguageStyled>
        </OutsideClickHandler>
      )}
    </LanguageContext.Consumer>
  )
}
const DropdownLanguageStyled = styled.div`
  .list-lang {
    .single-lang {
      cursor: pointer;
      text-align: center;
      transition: 300ms;
      user-select: none;
      &:hover {
        color: #94c835;
      }
      .flag {
        padding: 7px 0;
      }
    }
    .active-lang p.name {
      color: #94c835;
    }
  }
`
export const SingleLang = ({ language, selectLanguage, mobileView, intl }) => {
  return (
    <a
      key={selectLanguage}
      onClick={() => {
        language.handleCloseDropdown()
        language.setCurrentLanguage(selectLanguage)
        setTimeout(() => {
          changeLocale(selectLanguage)
        }, 300)
      }}
    >
      <div
        className={`single-lang ${
          language.currentLanguage === selectLanguage ? "active-lang" : ""
        }`}
      >
        <div className="flag">
          <img
            src={
              selectLanguage === "pl"
                ? pl
                : selectLanguage === "en"
                ? en
                : selectLanguage === "de"
                ? de
                : selectLanguage === "ru" && ru
            }
            alt=""
          />
        </div>
        {mobileView ? (
          <p className="name" style={{ textTransform: "uppercase" }}>
            {selectLanguage}
          </p>
        ) : (
          <p className="name">
                    {language.currentLanguage === "pl" &&
            (selectLanguage === "pl"
            ? "Polski"
            : selectLanguage === "en"
            ? "Angielski"
            : selectLanguage === "de"
            ? "Niemiecki"
            : selectLanguage === "ru" && "Rosyjski")
            }
            {language.currentLanguage === "en" &&
            (selectLanguage === "pl"
            ? "Polish"
            : selectLanguage === "en"
            ? "English"
            : selectLanguage === "de"
            ? "German"
            : selectLanguage === "ru" && "Russian")
            }
          </p>
        )}
      </div>
    </a>
  )
}
const LanguageDropdownStyled = styled.div`
  width: 49px;
  margin-left: 30px;
  user-select: none;
  z-index: 99999999999999;
  .language {
    cursor: pointer;
    .flag {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        align-self: center;
        //box-shadow: 0 3px 12px rgba(0,0,0,0.56);
      }
      p {
        padding-left: 5px;
        font-weight: 400;
        margin: 0;
        text-transform: uppercase;
      }
    }
  }
`
