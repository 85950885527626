import React from "react"
import { MobileButtonStyled } from "./buttonStyle"
import { PhoneButtonStyled, NavbarButtonStyled } from "./buttonStyle"
import { injectIntl } from "gatsby-plugin-intl"

const MobileButton = ({
  children,
  innerText,
  bg,
  color,
  handleAction,
  border,
  arrow,
  smallArrow,
  disabled,
  className,
  height,
}) => (
  <MobileButtonStyled
    arrow={arrow}
    border={border}
    onClick={() => handleAction && handleAction()}
    bg={bg}
    color={color}
    smallArrow={smallArrow}
    disabled={disabled}
    className={className}
    style={height ? { height } : undefined}
  >
    {innerText || children}
  </MobileButtonStyled>
)

const PhoneButton = ({ noIcon, innerText, phone, bg, color, narrower }) => (
  <a
    href={`tel:${
      innerText && innerText.startsWith("+")
        ? innerText.replace(/ /g, "")
        : "+48572361213"
    }`}
    onClick={() => {
      if (window.gtag_phonecall_conversion) {
        window.gtag_phonecall_conversion()
      }
    }}
  >
    <PhoneButtonStyled
      noIcon={noIcon}
      bg={bg}
      color={color}
      className={narrower ? "narrower" : ""}
    >
      {!noIcon && (
        <div className="icon-phone">
          <PhoneSVG />
        </div>
      )}
      <span>{innerText ? innerText : "+48 572 36 12 13"}</span>
    </PhoneButtonStyled>
  </a>
)

const EmailButton = ({ innerText, noIcon, narrower }) => (
  <a
    href={`mailto:${innerText ? innerText : "sprzedaz@willbud.pl"}`}
    onClick={() => window?.gtag_report_mailto_conversion()}
  >
    <PhoneButtonStyled
      bg={"#5D5D5D"}
      noIcon={noIcon}
      className={narrower ? "narrower" : ""}
    >
      {!noIcon && (
        <div className="icon-email">
          <EmailSVG />
        </div>
      )}
      <span>{innerText ? innerText : "sprzedaz@willbud.pl"}</span>
    </PhoneButtonStyled>
  </a>
)

const NavbarButttonIntl = ({ theme, className, intl }) => {
  return (
    <div className={"navbar-custom"}>
      <a
        href="tel:+48572361213"
        style={{ marginRight: 0 }}
        className={className}
        onClick={() => {
          if (window.gtag_phonecall_conversion) {
            window.gtag_phonecall_conversion()
          }
        }}
      >
        <NavbarButtonStyled theme={theme}>+48 572 36 12 13</NavbarButtonStyled>
      </a>
    </div>
  )
}

const PhoneSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill={"white"}>
      <path
        d="M25.302,0H9.698c-1.3,0-2.364,1.063-2.364,2.364v30.271C7.334,33.936,8.398,35,9.698,35h15.604
		c1.3,0,2.364-1.062,2.364-2.364V2.364C27.666,1.063,26.602,0,25.302,0z M15.004,1.704h4.992c0.158,0,0.286,0.128,0.286,0.287
		c0,0.158-0.128,0.286-0.286,0.286h-4.992c-0.158,0-0.286-0.128-0.286-0.286C14.718,1.832,14.846,1.704,15.004,1.704z M17.5,33.818
		c-0.653,0-1.182-0.529-1.182-1.183s0.529-1.182,1.182-1.182s1.182,0.528,1.182,1.182S18.153,33.818,17.5,33.818z M26.021,30.625
		H8.979V3.749h17.042V30.625z"
      />
    </svg>
  )
}
const EmailSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg">
      <g>
        <g>
          <g>
            <path d="M43,6H1C0.447,6,0,6.447,0,7v30c0,0.553,0.447,1,1,1h42c0.552,0,1-0.447,1-1V7C44,6.447,43.552,6,43,6z M42,33.581     L29.612,21.194l-1.414,1.414L41.59,36H2.41l13.392-13.392l-1.414-1.414L2,33.581V8h40V33.581z" />
          </g>
        </g>
        <g>
          <g>
            <path d="M39.979,8L22,25.979L4.021,8H2v0.807L21.293,28.1c0.391,0.391,1.023,0.391,1.414,0L42,8.807V8H39.979z" />
          </g>
        </g>
      </g>
    </svg>
  )
}
const NavbarButtton = injectIntl(NavbarButttonIntl)

export { MobileButton, NavbarButtton, EmailButton, PhoneButton }
