import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { MobileButton } from "@/components/shared/button/button"
import { injectIntl } from "gatsby-plugin-intl"

const CookiesBar = ({ intl }) => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      if (localStorage && localStorage.getItem("cookies")) {
        return
      }
      setActive(true)
    }, 1000)
  }, [setActive])

  function doAccept() {
    setActive(false)
    if (localStorage) {
      localStorage.setItem("cookies", "accept")
    }
  }

  return (
    <CookiesBarContainer className={`${active ? "active" : null}`}>
      <p>
        {intl.formatMessage({ id: "cookies.text" })}
        <Link to={"/polityka-prywatnosci"}>
          {intl.formatMessage({ id: "cookies.more" })}
        </Link>
      </p>
      <div>
        <MobileButton
          handleAction={doAccept}
          innerText={intl.formatMessage({ id: "cookies.accept" })}
          bg={"#94C835"}
          color={"white"}
          border={"transparent"}
          arrow={false}
        />
      </div>
    </CookiesBarContainer>
  )
}

export default injectIntl(CookiesBar)

export const CookiesBarContainer = styled.div`
  position: fixed;
  width: calc(100% - 32px);

  right: 16px;
  bottom: 16px;
  padding: 20px;
  z-index: 2000000001;
  border-radius: 4px;
  background: white;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);
  transition: 0.65s cubic-bezier(0.33, 0.99, 0.46, 1.19);
  transform: translateY(30px) scale(0.9);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  //
  //
  //@media (max-width: 1200px) {
  //  top: 280px;
  //  display: none;
  //}

  &.active {
    transform: translateY(0) scale(1);
    pointer-events: auto;
    opacity: 1;
    visibility: visible;
  }
  > p {
    a {
      color: black;
      font-weight: bold;
      margin-left: 1em;
    }
  }

  > div {
    text-align: center;

    button {
      max-width: 300px;
      font-size: 17px;
      height: 44px;
    }
  }
`
