export const jobOffers = [
  {
    name: "Specjalista ds. sprzedaży nieruchomości",
    segments: [
      {
        title: "NA TYM STANOWISKU BĘDZIESZ ODPOWIEDZIALNY ZA:",
        body: `
        <ul>
        <li>pozyskiwanie i kompleksowa obsługę Klientów w procesie sprzedaży nieruchomości deweloperskich,</li>
        <li>umawianie spotkań, oferowanie i prezentacja ofert nieruchomości np. mieszkania,</li>
        <li>wsparcie Klientów w zakresie kompletowania dokumentów,</li>
        <li>wsparcie w zakresie przekazywania mieszkań na rzecz Klientów,</li>
        <li>wsparcie w zakresie obsługi posprzedażowej,</li>
        <li>udział w zawieraniu transakcji związanych z podpisywaniem umów sprzedaży nieruchomości (rezerwacyjnych, deweloperskich, przeniesień własności, aneksów),</li>
        <li>prowadzenie negocjacji z Klientami.</li>
        </ul>
`,
      },
      {
        title: "OD KANDYDATÓW OCZEKUJEMY:",
        body: `<ul>
        <li>wysokiego poziomu umiejętności interpersonalnych (komunikatywność, budowanie relacji),</li>
        <li>pełnej dyspozycyjności,</li>
        <li>swobody wypowiedzi i zamiłowanie do pracy z ludźmi,</li>
        <li>pozytywnego nastawienia i determinacji w realizacji celów,</li>
        <li>zorganizowania i rzetelności,</li>
        <li>znakomitych umiejętności negocjacyjnych,</li>
        <li>motywacji do pracy oraz do osiągania założonych celów,</li>
        <li>doświadczenia w pracy z Klientem.</li>
        </ul>`,
      },
      {
        title: "MILE WIDZIANE:",
        body: `<ul>
        <li>doświadczenie w branży nieruchomości,</li>
        <li>znajomość branży budownictwa np. projektowanie, wykonawstwo.</li>
        </ul>`,
      },
      {
        title: "TO OFERUJEMY:",
        body: `<ul>
        <li>bardzo dobre warunki finansowe - podstawowe wynagrodzenie + premia,</li>
        <li>praca w branży, która szybko się rozwija i jest stabilna,</li>
        <li>realne możliwości rozwoju i podnoszenia kompetencji,</li>
        <li>miłą atmosferę pracy.</li>


        </ul>`,
      },
    ],
  },
  //
  /* {
    name: "",
    segments: [
      {
        title: "Twój zakres obowiązków",
        body: ``,
      },
      {
        title: "Nasze wymagania",
        body: ``
      },
      {
        title: "To oferujemy",
        body: ``
      }
    ],
  },*/
  /*{
    name: "",
    segments: [
      {
        title: "Twój zakres obowiązków",
        body: ``,
      },
      {
        title: "Nasze wymagania",
        body: ``
      },
      {
        title: "To oferujemy",
        body: ``
      }
    ],
  },*/
]
export const jobOffersCount = jobOffers.length
