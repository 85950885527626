import { DropdownStyled } from "./navbarStyle"
import janowopark from "../../images/slide1.png"
import { Link } from "gatsby-plugin-intl"
import locationIcon from "../../images/location.png"
import wiczlino1img from "../../images/wiczlino1.png"
import wiczlino2img from "../../images/wiczlino-2/z6g_mobile.jpg"
import janowoparktwo from "../../images/janowo-2/wiz-janowo-3_mobile.jpg"
import puckImage from "../../images/puck/z16-visual-mobile.jpg"
import ojp3Image from "../../images/z-17/Janowo_Park_III_01_V04_nav.jpg"
import React from "react"
import OutsideClickHandler from "react-outside-click-handler"
import { injectIntl } from "gatsby-plugin-intl"

const DropdownCompany = ({
  pose,
  handleHideDropdown,
  showDropdownArea,
  shortNavbar,
  intl,
}) => {
  return (
    <DropdownStyled pose={pose} shortNavbar={shortNavbar}>
      <OutsideClickHandler
        onOutsideClick={() => {
          showDropdownArea && handleHideDropdown()
        }}
      >
        <div className={"container-dropdown dropdown-company"}>
          <div className="title">Mieszkanie pod klucz</div>
          <Link to={"/mieszkanie-pod-klucz/zmiany-aranzacyjne"}>
            <div className="single-anchor">Zmiany aranżacyjne</div>
          </Link>
          <Link to={"/mieszkanie-pod-klucz/wykonczenie-pod-klucz"}>
            <div className="single-anchor">Wykończenia pod klucz</div>
          </Link>
        </div>
      </OutsideClickHandler>
    </DropdownStyled>
  )
}
export default injectIntl(DropdownCompany)
