import React from "react"
import PropTypes from "prop-types"
import Navigation from "./navbar/navbar"
import ThemeContext from "../context/ThemeContext"
import "react-image-lightbox/style.css"
import "bootstrap/dist/css/bootstrap.css"
import "../custom-css-bootstrap.css"
import styled from "styled-components"
import Footer from "./footer/footer"
import ProgramPopup from "@/components/program/programPopup"
import CookiesBar from "@/components/cookies/cookiesBar"

const Layout = ({
  children,
  handleOpenLanguageDropdown,
  handleCloseLanguageDropdown,
  showLanguageDropdown,
}) => {
  //console.log(handleOpenLanguageDropdown);
  return (
    <>
      <ThemeContext.Consumer>
        {theme => (
          <FontStyle>
            <div className="app-content">
              <MainContainer>
                <Navigation
                  showLanguageDropdown={showLanguageDropdown}
                  handleCloseLanguageDropdown={handleCloseLanguageDropdown}
                  handleOpenLanguageDropdown={handleOpenLanguageDropdown}
                />
                {children}
              </MainContainer>
              <CookiesBar />
              <Footer />
            </div>
          </FontStyle>
        )}
      </ThemeContext.Consumer>
    </>
  )
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const FontStyle = styled.div`
  font-family: "futura-pt", sans-serif !important;
  overflow: hidden;
`
const MainContainer = styled.div`
  position: relative;
  .padding30 {
    padding: 0 30px;
  }
`
export default Layout
