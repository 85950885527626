import styled from "styled-components"
import posed from "react-pose"

const NavbarPosed = {
  open: { height: "auto" },
  closed: { height: "90px" },
}
export const DropdownPosed = {
  show: {
    opacity: 1,
    top: "100px",
    applyAtStart: { display: "block" },
    transition: { duration: 230 },
  },
  hide: {
    opacity: 0,
    top: "70px",
    applyAtEnd: { display: "none" },
    transition: { duration: 230 },
  },
}

export const NavbarStyled = styled(posed.div(NavbarPosed))`
  overflow: hidden;
  height: 0;
  .btn-success:focus {
    border: none;
    outline: none;
  }
  .btn-success {
    border: none;
    outline: none;
  }
  font-family: "futura-pt", sans-serif !important;
  width: 100%;
  z-index: 999999;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  padding: 25px 30px 10px 30px;
  margin-bottom: 48px;

  .tablet-nav-button {
    display: none;
  }
  .navigation {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
  .nav-content {
    margin-top: 60px;
    ul {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 0;
      a,
      .dropdown-link,
      .dropdown-company {
        list-style-type: none;
        padding: 20px 0;
        border-bottom: #dadada;
        text-transform: uppercase;
        font-weight: 400;
        border-bottom: 1px solid #dadada;
        position: relative;
        user-select: none;
        color: #000;
        transition: 300ms;
        white-space: nowrap;
        &:hover {
          text-decoration: none;
        }
        > span {
          color: #94c835;
          margin-left: 0.35em;
          font-size: 0.9em;
        }
      }
      a:nth-last-of-type(1) {
        border: none;
        &:after {
          display: none;
        }
      }
      .dropdown-link {
        border-bottom: ${props =>
          props.collapseOffer ? "1px solid #fff" : "1px solid #dadada"};
      }
      .dropdown-company {
        border-bottom: ${props =>
          props.collapseCompanyMobile ? "1px solid #fff" : "1px solid #dadada"};
      }

      .dropdown-link:after,
      .dropdown-company:after {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        transform: rotate(45deg);
        content: "";
        width: 10px;
        height: 10px;
        border-top: 2px solid #94c835;
        border-right: 2px solid #94c835;
        transition: 300ms;
      }
      .dropdown-company:after {
        transform: rotate(
          ${props => (props.collapseCompanyMobile ? "135deg" : "45deg")}
        );
      }
      .dropdown-link:after {
        transform: rotate(
          ${props => (props.collapseOffer ? "135deg" : "45deg")}
        );
      }
    }

    .nav-content--lite {
      ul {
        a,
        .dropdown-link,
        .dropdown-company {
          > span {
            color: white;
          }
        }
      }
      color: white;
    }

    .mobile-investors-button {
      width: 100%;
      display: block;
      a {
        width: 100%;
      }
    }
    .mobile-investors {
      display: none;
    }
    .mobile-investors-hamburger {
    }
  }
  .active {
    color: #94c835 !important;
  }
  //@media (min-width: 856px) and (max-width: 1199px) {
  //  .tablet-nav-button {
  //    display: flex;
  //    justify-content: center;
  //    align-items: center;
  //  }
  //}
  @media (min-width: 1200px) {
    justify-items: center;
    padding: 0 30px 0 30px;
    min-height: 105px;
    background: white;
    // box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .mobile-button-everywhere-investors {
      display: none;
    }
    .tablet-nav-button {
    }
    .desktop-nav-button {
      //display: block;
      display: flex;
      align-items: center;
    }
    .mobile-nav-button {
      display: none !important;
    }
    .mobile-investors-hamburger {
      display: none;
    }
    position: relative;
    flex-direction: row;

    .nav-content {
      padding-top: 0;
      margin: 0;
      vertical-align: top;
      ul {
        flex-direction: row;
        padding: 0;
        margin: 0;
        align-items: center;
        a,
        .dropdown-link,
        .dropdown-company {
          vertical-align: top;
          margin: 0 15px;
          border: none;
          font-size: 16px;
          cursor: pointer;
          padding: 0;
          // margin-right: 28px;
          font-weight: 500;
          transition: 300ms;
          &:hover {
            color: #94c835;
          }
          &:after {
            display: none;
          }
        }
        .desktop {
          display: block;
        }
        .mobile {
          display: none;
        }
      }
    }
  }
  @media (max-width: 1200px) {
    position: relative;
    background: #fff;

    .desktop {
      display: none;
    }
  }
  @media (max-width: 1076px) {
    .nav-content {
      ul {
        a,
        .dropdown-link,
        .dropdown-company {
          margin: 0 10px;
        }
      }
    }
  }
  @media (max-width: 970px) {
    .nav-content {
      ul {
        a,
        .dropdown-link,
        .dropdown-company {
          margin: 0 5px;
        }
      }
    }
  }
  @media (min-width: 1200px) {
    margin-bottom: 65px;
    width: ${props => (props.shortNavbar ? "52%" : "100%")};
    padding-left: 30px;
    .nav-content {
      ul {
        a,
        .dropdown-link,
        .dropdown-company {
          margin: ${props => (props.shortNavbar ? "0 5px" : "0 15px")};
        }
      }
    }
  }

  @media (min-width: 1330px) {
    width: ${props => (props.shortNavbar ? "51%" : "100%")};
    .nav-content {
      ul {
        a,
        .dropdown-link,
        .dropdown-company {
          margin: ${props => (props.shortNavbar ? "0 10px" : "0 15px")};
        }
      }
    }
  }

  @media (min-width: 1441px) {
    .nav-content {
      ul {
        a,
        .dropdown-link,
        .dropdown-company {
          margin: 0 15px;
        }
      }
    }
  }
  @media (min-width: 2560px) {
    width: ${props => (props.shortNavbar ? "49%" : "100%")};
  }
`

export const HamburgerIconStyled = styled.div`
  display: initial;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  min-height: 42px;
  .hamburger {
    width: 20px;
    min-height: 42px;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding: 0;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    &:focus {
      outline: none;
    }
    .line {
      transition: all 0.2s ease-in-out;
      height: 1px;
      margin: 3px 0;
      background: #000;
      &:nth-child(1) {
        width: 100%;
      }
      &:nth-child(2) {
        width: 75%;
      }
      &:nth-child(3) {
        width: 50%;
      }
    }
  }
  .close-button {
    .line {
      transition: all 0.2s ease-in-out;
      &:nth-child(1) {
        width: 100%;
        transform: translateY(609%) rotate(45deg);
      }
      &:nth-child(2) {
        opacity: 0;
        width: 100%;
      }
      &:nth-child(3) {
        width: 100%;
        transform: translateY(-760%) rotate(-45deg);
      }
    }
  }
  @media (min-width: 1200px) {
    display: none;
  }
`
export const DropdownStyled = styled(posed.div(DropdownPosed))`
  position: absolute;
  display: none;
  right: ${props =>
    props.shortNavbar
      ? props.companyDropdown
        ? "48%"
        : "75%"
      : props.companyDropdown
      ? "275px"
      : props.languageDropdown
      ? "30px"
      : "540px"};
  @media (min-width: 855px) and (max-width: 1200px) {
    right: ${props =>
      props.shortNavbar
        ? props.companyDropdown
          ? "220px"
          : "450px"
        : props.companyDropdown
        ? "270px"
        : props.languageDropdown
        ? " 30px"
        : "450px"};
  }
  @media (min-width: 2560px) {
    right: ${props =>
      props.shortNavbar
        ? props.companyDropdown
          ? "51%"
          : "56%"
        : props.companyDropdown
        ? "275px"
        : props.languageDropdown
        ? "30px"
        : "545px"};
  }
  z-index: 9999999;
  background-color: #fff;
  padding: ${props => (props.languageDropdown ? "20px 20px 6px 20px" : "35px")};
  border-radius: 4px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  .container-dropdown {
    .title {
      font-size: 16px;
      font-weight: 600;
      border-bottom: 1px solid #94c835;
      text-transform: uppercase;
      margin-bottom: 13px;
      padding-bottom: 13px;
    }
    .single-investment {
      display: flex;
      flex-direction: row;
      padding: 13px 0 10px 0;
      .investment-image {
        width: 72px;
        margin-right: 20px;
        border-radius: 4px;
        overflow: hidden;
        img {
          height: 56px;
          border-radius: 4px;
        }
      }
      .content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        transition: 300ms;
        width: 100%;
        color: #000;

        p {
          margin: 0;
          font-size: 20px;
          font-weight: 500;
        }
        .location {
          font-weight: 300;
          font-size: 16px;
          img {
            margin-right: 8px;
            max-height: 21px;
            align-self: flex-start;
          }
        }
      }
      a.content:hover {
        color: #94c835;
      }
    }
  }
  .dropdown-company {
    .title {
      padding-right: 50px;
    }
    .single-anchor {
      color: #000;
      font-size: 20px;
      font-weight: 500;
      padding: 10px 0;
      transition: 300ms;
      &:hover {
        color: #94c835;
      }
      span {
        color: #94c835;
        display: inline-block;
        margin-left: 0.35em;
        font-weight: normal;
        font-size: 0.9em;
      }
    }
    a:nth-last-of-type(1) .single-anchor {
      padding-bottom: 0;
    }
  }

  @media (max-width: 1200px) {
    .desktop {
      display: none;
    }
  }
`

const OfferMenuMobilePosed = {
  openMenu: { height: "auto" },
  closedMenu: { height: "0" },
}
export const OfferMenuMobileStyled = styled(posed.div(OfferMenuMobilePosed))`
  overflow: hidden;
  padding-left: 30px;
  ul {
    padding: 0;
    margin-top: -20px;
  }
  a:after {
    display: none;
  }
  @media (min-width: 1200px) {
    display: none;
  }
`
