import styled from "styled-components"
export const CardBodyStyled = styled.div`
  .card-body {
    padding: 4px 24px 0 24px;
    font-size: 16px;
  }

  @media only screen and (min-width: 1200px) and (max-width: 1439px) {
    .narrower {
      width: 195px;
      font-size: 17px;
      .icon-email {
        width: 60px;
      }
    }
  }

  @media only screen and (max-width: 370px) {
    .narrower {
      width: 195px;
      font-size: 17px;
      .icon-email {
        width: 60px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .contact--sales_buttons {
      padding-left: 0;
      :first-of-type {
        margin-bottom: 1em;
      }
    }
  }
`
export const FooterStyled = styled.div`
  font-family: "futura-pt", sans-serif !important;
  background-color: #313131;
  padding: 46px 46px 65px 30px;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  a:hover {
    text-decoration: none;
  }
  .card-body,
  .column-styled {
    p {
      font-weight: 400;
      margin-bottom: 11px;
    }
    .subtitle {
      font-weight: 500;
      color: rgba(255, 255, 255, 0.69);
      border-bottom: 1px solid #646464;
      text-transform: uppercase;
      padding-bottom: 11px;
      margin-bottom: 11px;
    }
    .nav-link {
      padding: 0 0 21px 0;
      margin-bottom: 20px;
      border-bottom: 1px solid #646464;
      color: #fff;
      font-size: 20px;
      text-transform: uppercase;
    }
    &.company-sale {
      .title {
        padding-bottom: 43px;
      }
      > .row + .row {
        margin-top: 25px;
      }
    }
    &.company {
      height: 263px;
    }
    .last-contact-paragraph {
      margin-bottom: 1.5em;
      @media (min-width: 1200px) {
        margin-bottom: 6px;
      }
    }
  }
  .footer-mobile-nav {
    padding-top: 10px;
    a:nth-of-type(1) .nav-link {
      padding-top: 4px;
    }
  }
  .logo {
    padding: 0;
  }
  .mobile-nav {
    padding: 45px 0 0 0;
    .accordion {
      background: transparent;
      .card-header {
        background: transparent;
        border: none;
        padding: 0;
      }
    }
    .nav {
      a {
        display: inline-block;
        margin: 0.5em;
      }
    }
  }
  .social-media {
    width: 100%;
    display: flex;
    padding: 10px 0;
    justify-content: space-between;
    align-items: center;
    .icons {
      display: flex;
      flex-direction: row;
      a {
        :hover {
          ::before {
            content: "";
            position: absolute;
            width: 30px;
            height: 30px;
            background-color: rgba(148, 200, 53, 0.3);
            border-radius: 5px;
          }
        }
      }
      img {
        /* opacity: 0.6; */
        height: 30px;
        cursor: pointer;
      }
      img:first-child {
        margin-right: 14px;
        margin-top: -5px;
      }
    }
    @media (max-width: 375px) {
      flex-direction: column;
      align-items: initial;
      .list-lang {
        justify-content: flex-start;
        margin-bottom: 15px;
      }
    }
  }
  .list-lang {
    display: flex;
    justify-content: center;
    .single-lang {
      padding: 0 16px;
      cursor: pointer;
      p {
        margin: 0;
        color: #5d5d5d;
        font-size: 16px;
        text-align: center;
      }
    }
    .single-lang:nth-of-type(1) {
      padding-left: 0;
    }
    .active-lang p {
      color: #fff;
    }
  }
  .copyrights {
    border-top: 1px solid #5d5d5d;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (min-width: 364px) {
      flex-direction: row;
    }
    p {
      text-align: center;
      padding: 27px 0 10px;
      color: rgba(255, 255, 255, 0.69);
      font-size: 16px;
      margin: 0;
      @media (max-width: 363px) {
        &.copyrights--date {
          padding-top: 0;
        }
      }
    }
  }
  .nav {
    align-items: center;
    flex-direction: column;
    padding: 15px 0;

    a {
      color: rgba(255, 255, 255, 0.69);
      font-size: 16px;
      transition: 0.3s color linear;
      &:hover {
        color: rgb(148, 200, 53);
      }
    }
  }
  .developed {
    text-align: center;
    img {
      width: 80px;
    }
    p {
      display: inline-block;
      text-align: center;
      padding: 0 5px;
      color: rgba(255, 255, 255, 0.69);
      vertical-align: bottom;
      font-size: 16px;
      margin: 0;
    }
  }
  .flex-center {
    display: block;
    justify-content: center;
  }
  @media (min-width: 1200px) {
    padding: 90px 0 10px 0;
    .logo {
      p {
        font-size: 36px;
      }
      img {
        height: 60px;
      }
    }

    .footer-mobile-nav {
      padding-top: 0;
      a {
        div {
          transition: all linear 300ms;
          :hover {
            color: rgb(148, 200, 53);
          }
        }
      }
    }
    .desktop-container {
      display: flex;
      flex-direction: row;
      padding-bottom: 40px;
      max-width: 1800px;
      width: 100%;
      .container-data-right {
        display: flex;
        flex-direction: row;
        width: 100%;
        position: relative;
        margin-top: 20px;
        margin-left: 7%;
        @media (min-width: 1500px) {
          margin-left: 6%;
        }
        &:after {
          width: 100%;
          content: "";
          height: 1px;
          background-color: #5d5d5d;
          position: absolute;
          left: 0;
          top: 46px;
        }
      }
      .register-data-left {
        .subtitle {
          padding-top: 32px;
        }
      }
    }
    .column-styled {
      .title {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 20px;
        padding-bottom: 39px;
      }
      max-width: 100%;
      .subtitle {
        border: none;
        margin: 0;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 6px;
      }
    }
    .footer-desktop-nav {
      padding: 0;

      .title {
        padding-bottom: 44px;
      }
      div.nav-link {
        border: none;
        font-size: 16px;
        margin: 0;
        padding: 6.5px 0;
      }
      .social-media {
        border: none;
      }
    }
    .copyrights {
      display: flex;
      justify-content: space-between;
      padding-bottom: 55px;
      p {
        padding: 27px 0 0;

        a {
          color: inherit;
          margin-left: 30px;
          transition: 0.3s color linear;

          &:hover {
            color: rgb(148, 200, 53);
          }
        }
      }
    }
    .investments {
      padding-left: 60px;
      @media (min-width: 1200px) and (max-width: 1440px) {
        display: none;
      }
      @media (min-width: 1440px) {
        padding-left: 40px;
      }
      .title {
        padding-bottom: 44px;
      }
      .single-investment {
        display: flex;
        flex-direction: row;
        padding: 4px 0 6px;
        height: 104px;
        &:hover {
          .content .name {
            color: #94c835;
          }
          .content .location .name {
            color: #94c835;
          }
        }
        .content {
          margin-top: -5px;
          width: 100%;
          display: flex;
          //align-items: center;
          .location {
            display: flex;
            flex-direction: row;
            align-items: center;
            .icon {
              display: flex;
              align-items: center;
              img {
                margin-right: 9px;
                height: 21px;
              }
            }
            .name {
              padding: 0;
              color: #fff;
              font-weight: 300;
              font-size: 16px;
              transition: 300ms;
            }
          }
          .name {
            font-size: 20px;
            transition: 300ms;
            font-weight: 500;
            padding-bottom: 6px;
            color: #fff;
          }
          .button-flex {
            width: 100%;
            display: flex;
            justify-content: flex-start;
          }
          button {
            font-size: 16px;
            border: 1px solid #94c835;
            border-radius: 4px;
            background: transparent;
            font-weight: 500;
            color: #fff;
            padding: 4px 14px;
            text-transform: uppercase;
          }
        }
        img {
          max-height: 80px;
          margin-right: 15px;
        }
      }
    }
    .flex-center {
      display: flex;
      justify-content: center;
    }
  }

  .address-icon {
    img {
      height: 21px;
      margin-left: 10px;
    }
  }
`
export const SingleAccord = styled.div`
  background: transparent;
  text-transform: uppercase;
  color: #fff;
  font-weight: 400;
  padding: 20px 0;
  border-bottom: 1px solid
    ${props => (props.active ? "transparent" : "#646464")};
  position: relative;
  transition: 300ms;
  img {
    max-height: 26px;
  }
  &:after {
    transition: 300ms;
    position: absolute;
    right: 8px;
    top: 47%;
    transform: translateY(-50%);
    content: "";
    width: 12px;
    height: 12px;
    opacity: 0.2;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(${props => (props.active ? "135deg" : "45deg")});
  }
`
