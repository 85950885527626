import React, { useState } from 'react';
const defaultState = {
    modalOpen: false,
    toggleModal: () => {},
    img: false
  }
const ThemeContext = React.createContext(defaultState)

function ThemeProvider({children}) {
    const [modalOpen, setModalOpen] = useState(false)
    const [img, setImg] = useState(false)

const toggleModal = (img) => {
    setImg(img)
    setModalOpen(!modalOpen)
}

    return (
        <ThemeContext.Provider
        value={{
          toggleModal,
          modalOpen: modalOpen,
          img: img
        }}
      >
        {children}
      </ThemeContext.Provider>
    )
}

export default ThemeContext;

export { ThemeProvider };